import PropTypes from "prop-types";
import * as S from "./Card.styles";

const Card = ({ children }) => {
  return <S.Card>{children}</S.Card>;
};

Card.propTypes = {
  children: PropTypes.node,
};

export default Card;
