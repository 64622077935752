import styled from "styled-components";

export const Card = styled("div")`
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 0 0 0 0.06rem ghostwhite;
  padding: 2.5rem 1.5rem 2rem;
  position: relative;

  &::before {
    border-radius: 0.375rem;
    box-shadow: 0.12rem 0.12rem 0.12rem 0 hsl(0 0% 0% / 0.1);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
`;
