import "./App.css";
import { Card } from "./components";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Card>
          <p>Hi, I'm Liberty 👋🏽</p>
          <p>
            {" "}
            I'm a Senior Software Engineer at{" "}
            <a
              href="https://www.ibm.com/consulting/ibmix"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              IBM{" "}
            </a>
            👩🏾‍💻
          </p>
          <p>This is in progress, please come back later! </p>
          <p>
            For now you can find my LinkedIn 👉🏽{" "}
            <a
              href="https://www.linkedin.com/in/libertyjacklin/"
              target="_blank"
              rel="noreferrer noopener"
            >
              here
            </a>
          </p>
        </Card>
      </header>
    </div>
  );
}

export default App;
